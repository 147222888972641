<script setup lang="ts">
import Autoplay from 'embla-carousel-autoplay'

definePageMeta({
  title: 'Integrated Cable Systems',
  label: 'Home',
  icon: 'i-carbon-home',
  sort: 0,
})

const { data: stories } = await useFetch<(Story & { featured_image: Pick<DirectusFile, 'id' | 'title' | 'description'> })[]>(
  '/api/items/stories',
{
  query: {
    fields: [
      '*',
      'featured_image.id',
      'featured_image.title',
      'featured_image.description',
    ],
  },
  getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
},
)

const { data: tradeshows } = await useFetch<DefaultTradeshow[]>(
  '/api/items/tradeshows',
  {
    query: {
      filter: {
        status: {
          _eq: 'published',
        },
      },
      sort: 'start_date',
    },
    getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
  },
)
</script>

<template>
  <PageRoot>
    <PageHead
      :show-headline="false"
      :show-crumbs="false"
    >
      <TheMasthead />
    </PageHead>
    <PageBody class="space-y-12">
      <BlockContent
        element="section"
        class="space-y-4"
      >
        <p class="f-text-18-26 f-leading-28-36 font-flex">
          <span class="font-flex-440">ICS is an ISO 9001:2015 & AS9100D certified supplier</span> of specialized cable assemblies and wire harnesses based in Colorado, USA.
        </p>
        <TopLevelButtons />
      </BlockContent>
      <BlockContent
        element="section"
        class="space-y-4"
      >
        <div class="flex justify-between">
          <h2 class="text-xl font-flex-500-120">
            Latest Announcements
          </h2>
          <NuxtLink
            to="/about/tradeshows"
            class="text-sm link"
          >
            See all tradeshows
          </NuxtLink>
        </div>
        <hr class="mb-4 b-icssilver dark:b-icsjet">
        <ACarousel
          v-if="stories && tradeshows"
          nav
          :plugins="[Autoplay()]"
          class="[&_>_*]:f-mx-8-32"
        >
          <div
            v-for="story in stories"
            :key="story.id"
            class="flex flex-col justify-stretch gap-y-3"
          >
            <NuxtLink
              class="group space-y-4 heading-link-dull"
              to="/about/stories"
            >
              <NuxtImg
                v-if="story.featured_image"
                class="w-full rounded-sm object-cover f-h-280-200"
                :src="story.featured_image.id"
                :alt="`${story.featured_image.title}: ${story.featured_image.description}`"
                loading="lazy"
              />
              <EffectBgPattern
                v-else
                class="w-full rounded-sm f-h-280-200"
              />
              <h2 class="flex-initial text-lg capitalize font-flex-600-120">
                {{ story.title }}
              </h2>
            </NuxtLink>
            <div class="flex-1 basis-auto overflow-hidden">
              <p class="text-ellipsis">
                {{ story.teaser_content }}
              </p>
            </div>

            <div class="space-x-4">
              <NuxtLink
                to="/about/stories"
                class="inline-flex gap-1 text-sm link"
              >
                Read More
              </NuxtLink>
            </div>
            <div class="flex-initial text-sm text-icsgray-800 dark:text-icsgray-300">
              {{ useDateFormat(story.date_updated || story.date_created, 'MMMM D, YYYY @ h:mm a').value }}
            </div>
          </div>
          <ATradeshowContext
            v-for="tradeshow in tradeshows"
            :key="tradeshow.id"
            :tradeshow="tradeshow"
          >
            <template #default="{ to, headline, startDate, endDate, dateUpdated, dateCreated }">
              <div class="flex flex-col justify-stretch gap-y-3">
                <NuxtLink
                  :to="to"
                  class="group space-y-4 heading-link-dull"
                >
                  <NuxtImg
                    v-if="tradeshow.logo"
                    class="max-w-100 w-full rounded-sm object-contain object-left f-h-280-200 dark:bg-icsgray-100 dark:p-2"
                    :src="tradeshow.logo"
                    :alt="`The ${tradeshow.name} logo.`"
                    loading="lazy"
                  />
                  <EffectBgPattern
                    v-else
                    class="w-full rounded-sm f-h-280-200"
                  />
                  <h2 class="flex-initial text-lg font-flex-600-120">
                    {{ headline }}
                  </h2>
                </NuxtLink>
                <div class="flex-1 basis-auto overflow-hidden">
                  <div>
                    <p class="text-lg text-icsred">
                      {{ startDate }}
                    </p>
                    <p class="text-sm text-icsgray-800 dark:text-icsgray-300">
                      to {{ endDate }}
                    </p>
                    <p>
                      {{ tradeshow.city }}<span v-if="tradeshow.state !== 'Other'">, {{ tradeshow.state }}</span>
                    </p>
                  </div>
                </div>
                <div class="space-x-4">
                  <NuxtLink
                    :to="to"
                    class="inline-flex gap-1 text-sm link"
                  >
                    More Details
                  </NuxtLink>
                  <NuxtLink
                    :to="tradeshow.url"
                    class="inline-flex gap-1 text-sm link"
                  >
                    <div>Visit Show Site</div> <div class="i-carbon-launch translate-y-px scale-90" />
                  </NuxtLink>
                </div>
                <div class="flex-initial text-sm text-icsgray-800 dark:text-icsgray-300">
                  {{ dateUpdated || dateCreated }}
                </div>
              </div>
            </template>
          </ATradeshowContext>
        </ACarousel>
      </BlockContent>
    </PageBody>
  </PageRoot>
</template>
