<script setup lang="ts">
import gsap from 'gsap'

const config = useRuntimeConfig()

const playlist = [
  {
    text: 'Aerospace',
    webm: `${config.public.directusUrl}/assets/9dfc6a81-82bc-4f1d-8de9-db919ced161f`,
    mp4: `${config.public.directusUrl}/assets/dce4b71d-1360-4c53-91b7-35afc885a24d`,
    poster: `${config.public.directusUrl}/assets/b0b7ed5f-c900-498f-88c7-a3c4ea2b3d6f`,
  },
  {
    text: 'Military Electronics',
    webm: `${config.public.directusUrl}/assets/b91b78d7-0df9-428d-abba-44f843502b53`,
    mp4: `${config.public.directusUrl}/assets/9f06013f-f55e-40b3-a921-c6e055d0a751`,
    poster: `${config.public.directusUrl}/assets/4e4e2b69-00f4-47cc-b52f-52e17ed2c19c`,
  },
  {
    text: 'Medical Diagnostics',
    webm: `${config.public.directusUrl}/assets/cd418e91-4d92-4509-929d-e8975a7cea98`,
    mp4: `${config.public.directusUrl}/assets/ed93e0f6-39a2-4443-8628-ea8299f2cabd`,
    poster: `${config.public.directusUrl}/assets/e912f066-d81d-40d1-a32b-83b3f757e9ae`,
  },
  {
    text: 'Robotics',
    webm: `${config.public.directusUrl}/assets/2e144b8f-5117-43c5-9e8a-522f21fb5843`,
    mp4: `${config.public.directusUrl}/assets/f0242251-5c2b-4e28-8dd8-9485c1a3d6bc`,
    poster: `${config.public.directusUrl}/assets/5ae78927-1de2-45d5-91d7-87f19fc5cb21`,
  },
  {
    text: 'Semiconductor',
    webm: `${config.public.directusUrl}/assets/bed8d150-a75b-4f82-8387-442f2112050b`,
    mp4: `${config.public.directusUrl}/assets/c6af4455-2ef6-4048-991b-883434c2d758`,
    poster: `${config.public.directusUrl}/assets/b1fa5f82-97cc-4e94-8ce8-40f55c644e17`,
  },
  {
    text: 'Instrument & Test',
    webm: `${config.public.directusUrl}/assets/e9d4f5f6-6dc1-4b32-939d-4a7c33146ff0`,
    mp4: `${config.public.directusUrl}/assets/bf267b9b-aa92-465e-9cd0-816884c30e75`,
    poster: `${config.public.directusUrl}/assets/f79e4841-671b-4de7-bf13-cba6c37d3bb6`,
  },
]

const textEl = ref<HTMLDivElement>()

const currentTrack = ref(0)

function nextTrack() {
  if (currentTrack.value + 1 >= playlist.length)
    currentTrack.value = 0
  else
    currentTrack.value++
}

const on = {
  beforeEnter: (el: HTMLVideoElement) => {
    gsap.set(el, {
      filter: 'blur(8px)',
      opacity: 0,
    })

    if (textEl.value) {
      gsap.set(textEl.value, {
        left: '100%',
        opacity: 1,
      })
    }
  },

  enter: (el: HTMLVideoElement, done: () => void) => {
    if (el.paused)
      el.play()
    gsap.to(el, {
      duration: 0.6,
      filter: 'blur(0px)',
      opacity: 1,
      onComplete: done,
    })
  },

  afterEnter: () => {
    if (textEl.value) {
      gsap.to(textEl.value, {
        duration: 1.2,
        ease: 'power2.out',
        left: 0,
        opacity: 1,
      })
      gsap.to(textEl.value, {
        delay: 8,
        duration: 1.2,
        ease: 'power2.in',
        opacity: 0,
        left: '-100%',
        onComplete: nextTrack,
      })
    }
  },

  leave: (el: HTMLVideoElement, done: () => void) => {
    gsap.to(el, {
      duration: 0.6,
      filter: 'blur(8px)',
      opacity: 0,
      onComplete: done,
    })
  },
}
</script>

<template>
  <div class="bg-icsgray-950 f-h-512-672">
    <ClientOnly>
      <BlockContent class="absolute-inset z-2 flex items-center">
        <div class="relative w-full">
          <div
            ref="textEl"
            class="absolute max-w-full w-max text-4xl text-white heading-strong uppercase drop-shadow-md drop-shadow-color-icsblue-950/50 f-text-30-64"
          >
            {{ playlist[currentTrack]?.text }}
          </div>
        </div>
      </BlockContent>
      <div class="absolute-inset z-1 from-white/62 to-white/38 bg-gradient-to-b dark:from-icsgray-300/62 dark:to-icsgray-300/38" />
      <div class="absolute-inset z-1 bg-icsblue mix-blend-overlay" />
      <TransitionGroup
        appear
        :css="false"
        v-on="on"
      >
        <template v-for="track, index in playlist">
          <video
            v-if="index === currentTrack"
            :key="track.text"
            :poster="track.poster"
            autoplay
            loop
            muted
            playsinline
            preload="none"
            class="absolute object-cover size-full"
          >
            <source
              :src="track.webm"
              type="video/webm"
            >
            <source
              :src="track.mp4"
              type="video/mp4"
            >
          </video>
        </template>
      </TransitionGroup>
      <template #fallback>
        <div class="absolute-inset z-1 from-white/62 to-white/38 bg-gradient-to-b backdrop-blur" />
        <div class="absolute-inset z-1 bg-icsblue mix-blend-overlay" />
        <BlockContent class="absolute-inset z-2 flex items-center">
          <div class="relative w-full">
            <div class="absolute max-w-full w-max text-4xl text-white heading-strong uppercase drop-shadow-md drop-shadow-color-icsblue-950/50 f-text-30-64">
              {{ playlist[currentTrack]?.text }}
            </div>
          </div>
        </BlockContent>
        <img
          :src="playlist[0]?.poster"
          class="absolute object-cover size-full"
        >
      </template>
    </ClientOnly>
  </div>
</template>
